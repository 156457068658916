
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import GButton from '@/components/gsk-components/GskButton.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import EmptyState from '@/components/EmptyState.vue';
import { RouteNames, Statuses } from '@/constants';
import { PublishingModule } from '@/store/modules/publishing.module';
import HelpTooltip from '@/components/HelpTooltip.vue';
import { MyListing, MyListingVersion, adminRole } from '@/types/publishing.types';
import ListingEntry from '@/components/Publish/ListingEntry.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import * as API from '@/api/publishing.api';
import { EnumsModule } from '@/store/modules/enums.module';
import HelpLink from '@/components/HelpLink.vue';
import TablePagination from '@/components/TablePagination.vue';
import { userHasRole } from '@/utils/roles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img from '@/assets/computer.svg';

@Component({
  components: {
    GButton,
    MaxWidth,
    EmptyState,
    HelpTooltip,
    ListingEntry,
    DeleteDialog,
    GDialog,
    HelpLink,
    TablePagination,
  },
})
export default class MyListingsView extends Vue {
  private loading = true;
  private error = false;
  private deleteId = 0;
  private deleteVersionId: number | undefined = 0;
  private openDelete = false;
  private openDiscard = false;
  private adminDialog = false;
  private discardBug = false;
  private openNoNewVersions = false;
  public publishNew: string = RouteNames.PublishListing;
  private listingName = '';
  private listingPublishedDate = '';
  public isAdminMode = false;
  private isOwner = false;
  public isFeatured = false;
  public listingId = -1;

  public page = 1;
  public rowsPerPage = 10;

  get adminDialogContent(): adminRole[] {
    return [
      {
        title: 'Is Featured',
        radioValue: 'feature',
        checked: this.listingIsFeatured,
        content: 'Turn on to feature listing in catalog',
      },
      {
        title: 'Can Manage',
        radioValue: 'manage',
        checked: this.userIsOwner,
        content: 'Turn on to give yourself owner rights to this listing',
      },
    ];
  }

  get userIsOwner(): boolean {
    return this.isOwner;
  }

  set userIsOwner(isOwner: boolean) {
    this.isOwner = isOwner;
  }

  get listingIsFeatured(): boolean {
    return this.isFeatured;
  }

  set listingIsFeatured(isFeatured: boolean) {
    this.isFeatured = isFeatured;
  }

  get headerStyle() {
    return {
      backgroundColor: 'var(--theme-lightest)',
    };
  }

  get imageSrc() {
    return img;
  }

  get listings() {
    return PublishingModule.myListings;
  }

  get publishLink(): RawLocation {
    return { name: RouteNames.PublishListing };
  }

  public get hideBack(): boolean {
    return this.page < 2;
  }

  public get hideForward(): boolean {
    const maxPage = this.listings.length / this.rowsPerPage;
    return (this.page >= maxPage);
  }

  public get pageListings() {
    return this.listings.slice((this.page-1)*this.rowsPerPage, (this.page*this.rowsPerPage));
  }

  updateResults(rowsPerPage: number) {
    // Reset page number
    this.page = 1;
    this.rowsPerPage = rowsPerPage;
  }
  paginateBack() {
    this.page = this.page - 1;
  }
  public paginateForward() {
    this.page = this.page + 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleDelete(listing: MyListing, listingVersion: MyListing['versions'][0], isVersion: boolean) {
    let isDraft = listing.statusName !== Statuses.Published;
    this.deleteId = listing.listingId;
    this.deleteVersionId = listingVersion.listingVersionId;
    if (this.deleteVersionId) {
      isDraft = listingVersion.statusName !== Statuses.Published;
    }
    if (isDraft) {
      this.discardBug = listing.publishDate === null;
      this.openDiscard = true;
    } else {
      this.openDelete = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async openAdminDialog(listing: MyListing, listingVersion: MyListingVersion) {
    this.listingId = listing.listingId;
    const { isCurrentOwner, isFeaturedFlag } = await this.getAdminSettings(listing.listingId);
    this.adminDialog = true;
    this.listingName = listing.listingName;
    const date = new Date(listing.publishDate);
    this.listingPublishedDate = date.toLocaleDateString();
    this.userIsOwner = isCurrentOwner;
    this.listingIsFeatured = isFeaturedFlag;
  }

  async handleUnpublish(listing: MyListing, listingVersion: MyListingVersion, isVersion: boolean) {
    if (isVersion) {
      await API.unpublishListingVersion(listingVersion.listingVersionId);
      await this.loadListings();
    } else {
      await API.unpublishListing(listing.listingId);
      await this.loadListings();
    }
    await this.loadListings();
  }

  discardDraft() {
    let p: Promise<boolean>;
    if (this.deleteVersionId) {
      if (this.discardBug) {
        p = PublishingModule.deleteDraftListingVersion(this.deleteVersionId);
      } else {
        p = PublishingModule.discardDraftListingVersion(this.deleteVersionId);
      }
    } else {
      if (this.discardBug) {
        p = PublishingModule.deleteDraftListing(this.deleteId);
      } else {
        p = PublishingModule.discardDraftListing(this.deleteId);
      }
    }
    this.afterDelete(p);
  }

  deleteListing() {
    if (this.deleteVersionId) {
      this.afterDelete(PublishingModule.deleteDraftListingVersion(this.deleteVersionId));
    } else {
      this.afterDelete(PublishingModule.deleteDraftListing(this.deleteId));
    }
  }

  /**
   * seems to check if there are any unused registration versions
   */
  async canPublishNewVersion(listing: MyListing): Promise<boolean> {
    try {
      // this seems to get available versions
      const { versions } = (await API.getRegistrationVersions(listing.registrationId)).data;
      if (versions === null) {
        // null return instead of empty array from postgres
        return false;
      } else {
        return !!versions.length;
      }
    } catch (e) {
      this.$log('error', e);
      return false;
    }
  }

  afterDelete(p: Promise<unknown>) {
    return p
      .then(() => {
        this.openDiscard = false;
        this.openDelete = false;
        return this.loadListings();
      })
      .finally(() => {
        this.openDiscard = false;
        this.openDelete = false;
      });
  }

  async addNewVersion(listing: MyListing) {
    const canPublish = await this.canPublishNewVersion(listing);
    if (canPublish) {
      await PublishingModule.getRegistrationVersions(listing.registrationId);
      this.$router.push({
        name: RouteNames.PublishListing,
        params: {
          listingId: listing.listingId.toString(),
        },
        query: { mode: 'version' },
      });
    } else {
      this.openNoNewVersions = true;
    }
  }

  loadListings() {
    // Reset Page Number
    this.page = 1;
    const method = this.isAdminMode ? 'getAllListings' : 'getMyListings';
    return PublishingModule[method]()
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  addSelfAsOwner() {
    API.addSelfAsOwner(this.listingId, !this.userIsOwner);
    this.loadListings();
  }

  setListingAsFeatured() {
    API.setListingAsFeatured(this.listingId, !this.listingIsFeatured);
    this.loadListings();
  }

  async getAdminSettings(
    listingId: number,
  ): Promise<{ isCurrentOwner: boolean; isFeaturedFlag: boolean }> {
    return (await API.getAdminSettings(listingId)).data;
  }

  publishNewListing() {
    PublishingModule.resetPublishingState();
  }

  useAdminMode() {
    this.isAdminMode = !this.isAdminMode;
    this.loadListings();
  }

  async created() {
    await this.loadListings();
  }

  get isAdmin(): boolean {
    return userHasRole(EnumsModule.enums.role.LISTINGADMIN.id);
  }

  checkedSwitch(content: adminRole) {
    const selectedSwitch = content.radioValue;
    if (selectedSwitch === 'manage') {
      this.addSelfAsOwner();
    } else if (selectedSwitch === 'feature') {
      this.setListingAsFeatured();
    }
  }
}
